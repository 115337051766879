import { defineStore } from 'pinia';
import { hasUrlParam, getUrlParam, SEARCH_PARAMS } from '../../utils/urls.js';

const METHOD = {
  AUTH: 'authenticate',
  DOWNLOAD: 'downloadPattern',
  BACK: 'navigateBack',
  QA: 'openQA',
  PHOTO_GALLERY: 'openGallery'
};
const ENTITY_TYPE = {
  PRODUCT: 'product',
  PATTERN: 'pattern'
};

export const useWebviewStore = defineStore('webview', {
  state: () => ({
    redirectUrl: 'https://onet.pl',
    patternId: null,
    entityType: null,
    entityId: null,
    entityName: null
  }),
  getters: {
    isWebview() {
      return document.querySelector(':root').classList.contains('is-webview');
    }
  },
  actions: {
    executeNativeMethod(method, arg1, arg2, arg3) {
      if (window.AndroidInterface && window.AndroidInterface[method]) {
        switch (method) {
          case METHOD.AUTH:
            window.AndroidInterface[method](arg1);
            break;
          case METHOD.DOWNLOAD:
            window.AndroidInterface[method](arg1, arg2);
            break;
          case METHOD.BACK:
            window.AndroidInterface[method]();
            break;
          case METHOD.QA:
          case METHOD.PHOTO_GALLERY:
            window.AndroidInterface[method](arg1, arg2, arg3);
            break;
          default:
            console.error('Unknown method: ' + method);
        }
      } else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers[method]
      ) {
        switch (method) {
          case METHOD.AUTH:
            window.webkit.messageHandlers[method].postMessage({
              redirectUrl: arg1
            });
            break;
          case METHOD.DOWNLOAD:
            window.webkit.messageHandlers[method].postMessage({
              patternUrl: arg1,
              patternId: arg2
            });
            break;
          case METHOD.BACK:
            window.webkit.messageHandlers[method].postMessage({});
            break;
          case METHOD.QA:
          case METHOD.PHOTO_GALLERY:
            window.webkit.messageHandlers[method].postMessage({
              entityType: arg1,
              entityId: arg2,
              entityName: arg3
            });
            break;
          default:
            console.error('Unknown method: ' + method);
        }
      } else {
        console.error('No native APIs found.');
      }
    },
    goBack() {
      if (document.referrer) {
        const key = SEARCH_PARAMS.FROM_SEARCH;
        const goingBackToSearch = hasUrlParam(key);

        if (goingBackToSearch) {
          const prevURL = new URL(document.referrer);
          prevURL.searchParams.append(
            SEARCH_PARAMS.PRELOAD_SEARCH,
            getUrlParam(key)
          );
          window.location.href = prevURL.href;
        } else {
          if (history.length > 1) {
            history.back();
          } else {
            this.executeNativeMethod(METHOD.BACK);
          }
        }
      } else {
        this.executeNativeMethod(METHOD.BACK);
      }
    },
    authenticate() {
      this.executeNativeMethod(METHOD.AUTH, 'https://onet.pl');
    },
    downloadPattern(patternURL, patternId) {
      this.executeNativeMethod(METHOD.DOWNLOAD, patternURL, patternId);
    },
    openQA(entityId, name, entityType = ENTITY_TYPE.PRODUCT) {
      this.executeNativeMethod(METHOD.QA, entityType, entityId, name);
    },
    openPhotoGallery(entityId, name, entityType = ENTITY_TYPE.PRODUCT) {
      this.executeNativeMethod(
        METHOD.PHOTO_GALLERY,
        entityType,
        entityId,
        name
      );
    }
  }
});
